import React from "react";
import { Row, Col, Upload, Button, Image } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import { useMobxStores } from "../../../stores/stores";

const ThreeDModelCover = ({
  onRemove,
  handleCover,
  previewImage,
  imageName,
}) => {
  const { styleStore, marketplaceStore } = useMobxStores();
  return (
    <>
      <Row gutter={[0, 8]}>
        <Col style={{ marginBottom: "16px" }}>
          Enhance your 3D Model with an image to display cover.
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Col>
          <Upload
            onRemove={onRemove}
            style={{
              alignItems: "center",
              width: 600,
            }}
            maxCount={1}
            showPreviewIcon={true}
            showUploadList={marketplaceStore.sourceIsUploading}
            onChange={(file) => handleCover(file)}
            customRequest={() => {}}
            accept=".jpeg, .png"
          >
            <Button icon={<PlusOutlined />}>Add Image</Button>
          </Upload>
          <div className="accept-types" style={{ marginBottom: "16px" }}>
            Acceptable file formats: .jpeg, .png
          </div>
          {previewImage && (
            <>
              <p style={{ color: styleStore.primaryColor }}>{imageName}</p>
              <Image
                src={previewImage}
                style={{ marginBottom: "16px" }}
              ></Image>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default observer(ThreeDModelCover);
