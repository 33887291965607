import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Select,
  Form,
  Input,
  Upload,
  Row,
  Col,
  Image,
  Steps,
  InputNumber,
  Divider,
  Checkbox,
  Popover,
  Space,
  Avatar,
} from "antd";
import {
  UploadOutlined,
  PlusOutlined,
  InfoCircleOutlined,
  FileZipOutlined,
  LoadingOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import Loader from "../loader/Loader";
import ModelPreview from "./Model";
import { constants } from "../../consts/Variables";
import ThreeDModelCover from "./ItemComponents/ThreeDModelCover";
import ModelViewer from "./ModelViewer";

const { Option } = Select;

const CreateItem = () => {
  const { userStore, styleStore, marketplaceStore, errorStore } =
    useMobxStores();
  const [previewImage, setPreviewImage] = useState(null);
  //const [previewVideo, setPreviewVideo] = useState(null);
  //const [previewAudio, setPreviewAudio] = useState(null);
  const [previewSoftware, setPreviewSoftware] = useState(null);
  const [chooseNetworkIsDisabled, setChooseNetworkIsDisabled] = useState(false);
  const [preview, setOnPreview] = useState(false);
  const [preview3D, setPreview3D] = useState(null);
  const [current, setCurrent] = useState(0);
  const [unlockable_content, setUnlockableContent] = useState(false);
  const [NFT, setNFT] = useState(false);
  // const [free, setFree] = useState(false);
  const [display_image, setDisplayImage] = useState(null);
  // const [display_video, setDisplayVideo] = useState(null);
  const [software, setSoftware] = useState(null);
  const [moocURL, setMoocURL] = useState(null);
  const [NFTFormFields, setNFTFormFields] = useState([]);
  const [ItemFormFields, setItemFormFields] = useState([]);
  const [type, setType] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [urlEnabledNext, setUrlEnabledNext] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [form_nft] = Form.useForm();
  const [form_item] = Form.useForm();
  const [priceLabel, setPriceLabel] = useState("");

  useEffect(() => {
    marketplaceStore.GetTags();
    marketplaceStore.GetLicense();
  }, []);

  const contentStyle = {
    lineHeight: "260px",
    textAlign: "center",
    marginTop: 16,
  };

  const onTypeChanged = (e) => {
    console.log(e);
    setType(e);
  };

  const resetForm = (form) => {
    form.resetFields();
    setType(null);
  };

  const onChangeUnlockableContent = (e) => {
    setUnlockableContent(!unlockable_content);
  };

  const beforeUpload = (file) => {
    /*const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error(textStore.text["validate_image_error"]);
    }
    return isJpgOrPng && isLt2M;*/
  };

  const onContentManagementItemClicked = (item) => {
    // setType(item.contentType);
    // setCurrent(current + 1);
    // setNFTFormFields([
    //   {
    //     name: "name",
    //     value: item.name,
    //   },
    // ]);
    // setItemFormFields([
    //   {
    //     name: "name",
    //     value: item.name,
    //   },
    //   {
    //     name: "description",
    //     value: item.description,
    //   },
    //   {
    //     name: "tags",
    //     value: item.tagList,
    //   },
    // ]);
    // const values = {
    //   type: item.contentType === "Art" ? "image" : item.contentType,
    //   media: `${constants.IPFS}` + item.uri,
    // };
    // marketplaceStore.CreateSource(values);
  };

  const onCreateItem = (fields) => {
    setPreviewImage(null);
    setOnPreview(false);
    const postData = new FormData();
    if (display_image) {
      postData.append("display_image", display_image.file.originFileObj);
    }
    postData.append("owner", userStore.user.id);
    fields.name && postData.append("name", fields.name);
    NFT && postData.append("nft", marketplaceStore.NFT.id);
    fields.description && postData.append("description", fields.description);
    type && postData.append("type", type);

    fields.tags &&
      fields.tags.map((item) => {
        postData.append("tags", item);
      });

    fields.unlockable_content &&
      postData.append("unlockable_content", fields.unlockable_content);
    fields.external_link &&
      postData.append("external_link", fields.external_link);
    postData.append("source", marketplaceStore.source.id);
    marketplaceStore.CreateMarketPlaceItem(postData);
    form_item.resetFields();
    setCurrent(0);
    setUrlEnabledNext(false);
    setSoftware(null);
    setMoocURL(null);
    setFileName(null);
    setType(null);
    marketplaceStore.sourceIsUploaded = false;
    marketplaceStore.sourceIsUploading = false;
  };

  console.log(localStorage.getItem("metaMask"));

  const onCreateNFT = (fields) => {
    fields.creator = localStorage.getItem("metaMask");
    fields.chainid = window.ethereum.networkVersion;
    marketplaceStore.CreateNFT(fields);
    setCurrent(current + 1);
    form_nft.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const OnNextClick = () => {
    const values = {
      type: type === "MOOC" ? "mooc" : type,
      media:
        type === "software" && software
          ? software
          : type === "MOOC" && moocURL
          ? moocURL
          : marketplaceStore.source_link,
    };
    current === 0 && marketplaceStore.CreateSource(values);
    current === 1 && !NFT && marketplaceStore.CreateSource(values);
    setCurrent(current + 1);
  };

  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      marketplaceStore.setIsMarketplaceCreateModalOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    marketplaceStore.setIsMarketplaceCreateModalOpen(false);
    setCurrent(0);
    marketplaceStore.setNFT({ name: "", price: "", license: "", sypply: "" });
    if (preview || previewImage) {
      setOnPreview(null);
      setPreviewImage(null);
      setPreview3D(null);
      //setPreviewAudio(null);
      setPreviewSoftware(null);
      // setPreviewVideo(null);
    }
    setFileName(null);
    setSoftware(null);
    setErrorMessage(null);
    setUrlEnabledNext(false);
    marketplaceStore.sourceIsUploaded = false;

    form_item && resetForm(form_item);
    current === 2 && NFT && resetForm(form_nft);
    setNFT(false);
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const onRemove = () => {
    //setDisplayVideo(null);
    setDisplayImage(null);
    setOnPreview(false);
    //setPreviewVideo(null);
    setPreviewImage(null);
    //setPreviewAudio(null);
    setPreview3D(null);
    setPreviewSoftware(null);
    setType(null);
    form_item.resetFields();
  };

  const handleCover = async (file) => {
    if (file) {
      setDisplayImage(file);
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.file.originFileObj);
      }
    }
    setPreviewImage(file.url || file.preview);
    setImageName(file.file.name);
  };

  const onPreview = async (file) => {
    // if (type === "3D") {
    //   setDisplayImage(file);
    //   if (!file.url && !file.preview) {
    //     file.preview = await getBase64(file.file.originFileObj);
    //   }
    //   setOnPreview(true);
    //   setPreviewImage(file.url || file.preview);
    // }

    // if (type === "video") {
    //   setDisplayVideo(file.url || file.preview);
    //   if (!file.url && !file.preview) {
    //     file.preview = await getBase64(file.file.originFileObj);
    //   }
    //   setPreviewVideo(file.url || file.preview);
    //   setOnPreview(true);
    // }

    if (type === "3D") {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.file.originFileObj);
      }
      setPreview3D(file.url || file.preview);
      setOnPreview(true);
      // handle3DFile(file);
    }
    if (type === "software") {
      setPreviewSoftware(file.file.name);
    }
    setFileName(file.file.name);

    const postData = new FormData();
    postData.append("file", file.file.originFileObj);
    marketplaceStore.AddSourceToIPFS(postData);
  };

  const handleRemoveImage = () => {
    setDisplayImage(null);
  };
  let str = "";
  const getPriceLabel = async (chainId) => {
    const label = `Price ${chainId === 11155111 ? "(ETH)" : "(MATIC)"}`;
    setPriceLabel(label);
  };
  const OnNetworkChange = async (chainId) => {
    console.log(chainId);
    if (window.ethereum.networkVersion !== chainId) {
      setChooseNetworkIsDisabled(true);
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${chainId.toString(16)}` }],
        });
        errorStore.setMessage("Network Changed");
        errorStore.setInfo(true);
        setChooseNetworkIsDisabled(false);
      } catch (err) {
        console.log(err);
        // This error code indicates that the chain has not been added to MetaMask
        if (err.code === 4902) {
          setChooseNetworkIsDisabled(true);
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                // chainName: "Mumbai Testnet",
                chainName: "Amoy Testnet",
                chainId: `0x${chainId.toString(16)}`,
                nativeCurrency: {
                  name: "Matic",
                  decimals: 18,
                  symbol: "MATIC",
                },
                rpcUrls: ["https://rpc-amoy.polygon.technology/"],
              },
            ],
          });
          setChooseNetworkIsDisabled(false);
        } else {
          errorStore.setMessage(err.message);
          errorStore.setError(true);
          setChooseNetworkIsDisabled(false);
        }
      }
    }
    getPriceLabel(chainId);
  };

  const handleMoocLinkChange = (url) => {
    var res = url.match(/^(https?:\/\/)skillsmove\.eu(?:\/.*)?$/);
    setUrlEnabledNext(false);
    if (url === "") {
      setErrorMessage("");
      setMoocURL(null);
    } else if (res) {
      setErrorMessage("");
      setMoocURL(url);
      setUrlEnabledNext(true);
    } else {
      setMoocURL(null);
      setErrorMessage("Enter a valid URL");
    }
  };

  const handleSoftwareLinkChange = (url) => {
    var res = url.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    setUrlEnabledNext(false);
    if (url === "") {
      setErrorMessage("");
      setSoftware(null);
    } else if (res) {
      setErrorMessage("");
      setSoftware(url);
      setUrlEnabledNext(true);
    } else {
      setSoftware(null);
      setErrorMessage("Enter a valid URL");
    }
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
        padding: "16px",
      }}
      type="button"
    >
      {marketplaceStore.sourceIsUploading ? (
        <LoadingOutlined />
      ) : (
        <PlusOutlined />
      )}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Display Image
      </div>
    </button>
  );

  const types = [
    {
      label: "software",
      value: "software",
      format: ".zip, .dmg",
    },
    {
      label: "3D",
      value: "3D",
      format: ".obj, .fbx, .stl, .geb, .stp, .getf, .zip, .rar",
    },
    {
      label: "dataset",
      value: "dataset",
      format: ".csv, .zip, .json",
    },
    {
      label: "text",
      value: "text",
      format: ".txt, .pdf, .docx",
    },
    {
      label: "Massive Open Online Course (MOOC)",
      value: "MOOC",
    },
    // {
    //   label: "Work Repository",
    //   value: "work-repo",
    // },
  ];

  const tags = marketplaceStore.tags;

  const networks = [
    {
      label: "Sepolia (Ethereum Testnet)",
      value: 11155111,
      avatar_url: marketplaceStore.ethereum_icon,
      description:
        "Ethereum is the most decentralised and popular blockchain network. This network is a great choice for those who would like to reach a wider audience. The costs in this network can be relatively high.  Learn more at the FAQ.",
    },
    {
      // label: "Mumbai (Polygon Testnet)",
      label: "Amoy  (Polygon Testnet)",
      value: 80002,
      avatar_url: marketplaceStore.polygon_icon,
      description:
        "Polygon network is based on Ethereum and has been optimised in regards to speed and cost. Great choice for those who would wish to keep the costs low. Learn more at the FAQ.",
    },
  ];
  const onButtonClick = () => {
    // Get the values of the "Name" and "Description" fields from the NFT form
    const name = form_nft.getFieldValue("name");
    console.log(name);
    // Set the values of the "Name" and "Description" fields in the current form
    form_item.setFieldsValue({
      name,
    });
  };

  const steps = NFT
    ? [
        {
          title: "Upload",
        },
        {
          title: "Distribution",
        },
        {
          title: "NFT",
        },
        {
          title: "Marketplace Listing",
        },
      ]
    : [
        {
          title: "Upload",
        },
        {
          title: "Distribution",
        },

        {
          title: "Marketplace Listing",
        },
      ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const content_management_options = [];

  marketplaceStore.content_management_items.map((item) => {
    content_management_options.push({
      value: item,
      label: item.name,
    });
  });

  return (
    <div>
      <Modal
        forceRender
        title="Create Item"
        open={marketplaceStore.IsMarketplaceCreateModalOpen}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        width={"50%"}
        style={{ textAlign: "center", height: "100%", minWidth: "500px" }}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => setCurrent(current - 1)}
            disabled={current === 0}
          >
            Previous
          </Button>,
          <Button
            key="link"
            onClick={() => OnNextClick()}
            disabled={
              current === 3 ||
              (current === 0 && type === null) ||
              (!NFT && current === 2) ||
              (NFT && current === 2) ||
              chooseNetworkIsDisabled ||
              !(
                (type &&
                  marketplaceStore.sourceIsUploaded &&
                  !marketplaceStore.sourceIsUploading) ||
                urlEnabledNext
              )
            }
          >
            Next
          </Button>,
        ]}
      >
        {!marketplaceStore.marketPlaceItemIsCreating ? (
          <>
            {/* {type === "3D" && <ModelPreview />} */}

            <Steps current={current} items={items} responsive={true} />
            <div style={contentStyle}>{steps[current].content}</div>
            <Form
              name="create_items"
              initialValues={{
                remember: true,
              }}
              onFinish={onCreateItem}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
              size="large"
              form={form_item}
              fields={ItemFormFields}
            >
              {/* step 1 */}
              {current === 0 && (
                <>
                  <Form.Item
                    label="Select the type of asset you want to publish"
                    name="type"
                    rules={[
                      {
                        required: false,
                        message: "Please input your type!",
                      },
                    ]}
                  >
                    <Select
                      placeholder={"Type"}
                      onChange={(e) => onTypeChanged(e)}
                      disabled={
                        marketplaceStore.sourceIsUploaded || software || moocURL
                      }
                    >
                      {types.map((item, index) => {
                        return (
                          <Option key={index} value={item.value}>
                            {item.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </>
              )}
              {current === 0 && (
                <Row gutter={[20, 20]} align={"top"} justify={"space-around"}>
                  <Col span={24} md={24} sm={24} lg={24} xxl={24}>
                    <p>
                      {marketplaceStore.sourceIsUploading && (
                        <b style={{ color: styleStore.primaryColor }}>
                          Wait, your file is uploading...
                        </b>
                      )}
                    </p>
                    <Row>
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={(file) => handleCover(file)}
                        disabled={!type || previewImage}
                        customRequest={() => {}}
                        accept=".jpg, .png"
                      >
                        {previewImage ? (
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              position: "relative",
                            }}
                          >
                            <Image
                              src={previewImage}
                              alt="avatar"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                            />
                            <button
                              onClick={handleRemoveImage}
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                border: "none",
                                background: "none",
                                padding: 0,
                                margin: "4px",
                                zIndex: 1,
                                cursor: "pointer",
                              }}
                            >
                              <CloseOutlined
                                style={{ fontSize: "16px", color: "red" }}
                              />
                            </button>
                          </div>
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </Row>
                    {type !== "software" && type !== "MOOC" && (
                      <Form.Item
                        label={"Upload your file"}
                        name="display_image"
                        rules={[
                          {
                            required: false,
                            message: "Please choose a file!",
                          },
                        ]}
                        style={{ alignItems: "center" }}
                      >
                        {!marketplaceStore.sourceIsUploading && (
                          <div>
                            <p style={{ color: styleStore.primaryColor }}>
                              {fileName}
                            </p>
                          </div>
                        )}
                        <Upload
                          disabled={type === null ? true : false}
                          onRemove={() => onRemove()}
                          style={{
                            alignItems: "center",
                            width: 600,
                            minWidth: "auto",
                          }}
                          maxCount={1}
                          showPreviewIcon={true}
                          showUploadList={marketplaceStore.sourceIsUploading}
                          onChange={(file) => onPreview(file)}
                          customRequest={() => {}}
                          beforeUpload={beforeUpload}
                          accept={
                            type === "dataset"
                              ? ".csv, .zip, .json"
                              : type === "text"
                              ? ".txt, .pdf, .docx"
                              : ".obj, .fbx, .stl, .geb, .stp, .getf, .zip, .rar"
                          }
                        >
                          <Button
                            disabled={type === null ? true : false}
                            icon={<UploadOutlined />}
                          >
                            {type === null ? "Select type" : "Click to Upload"}
                          </Button>
                        </Upload>
                        {type && (
                          <div className="accept-types">
                            {" "}
                            Acceptable file formats:{" "}
                            {types.find((t) => t.label === type)?.format}
                          </div>
                        )}
                      </Form.Item>
                    )}
                    {type === "3D" && (
                      <ThreeDModelCover
                        onRemove={onRemove}
                        handleCover={handleCover}
                        previewImage={previewImage}
                        imageName={imageName}
                      />
                    )}
                    {/* {type === "audio" && (
                      <>
                        <Row gutter={[0, 8]}>
                          <Col style={{ marginBottom: "16px" }}>
                            Enhance your audio experience by adding an album
                            cover.
                          </Col>
                        </Row>
                        <Row justify="center" align="middle">
                          <Col>
                            <Upload
                              onRemove={() => onRemove()}
                              style={{
                                alignItems: "center",
                                width: 600,
                              }}
                              maxCount={1}
                              showPreviewIcon={true}
                              showUploadList={
                                marketplaceStore.sourceIsUploading
                              }
                              onChange={(file) => handleCover(file)}
                              customRequest={() => {}}
                              accept=".jpeg, .png"
                            >
                              <Button icon={<PlusOutlined />}>Add Image</Button>
                            </Upload>
                            <div
                              className="accept-types"
                              style={{ marginBottom: "16px" }}
                            >
                              Acceptable file formats: .jpeg, .png
                            </div>
                            {previewImage && (
                              <>
                                <p style={{ color: styleStore.primaryColor }}>
                                  {imageName}{" "}
                                </p>
                                <Image
                                  src={previewImage}
                                  style={{ marginBottom: "16px" }}
                                ></Image>
                              </>
                            )}
                          </Col>
                        </Row>
                      </>
                    )} */}

                    {type === "software" && (
                      <>
                        <Row gutter={[0, 8]}>
                          <Col>Please provide a link to the software:</Col>
                        </Row>
                        <Row gutter={[0, 8]}>
                          <Col span={24} style={{ marginBottom: "16px" }}>
                            <Input
                              type="url"
                              placeholder="Enter a Gitlab or Github Link"
                              onChange={(e) =>
                                handleSoftwareLinkChange(e.target.value)
                              }
                              disabled={previewSoftware}
                            />
                            {errorMessage && (
                              <div style={{ color: "red" }}>{errorMessage}</div>
                            )}
                          </Col>
                        </Row>
                        <Row justify="center" align="middle" gutter={[0, 8]}>
                          <Col
                            justify="center"
                            align="middle"
                            style={{ marginBottom: "16px" }}
                          >
                            Or Upload your file:
                          </Col>
                        </Row>
                        <Row justify="center" align="middle" gutter={[0, 8]}>
                          <Col>
                            {previewSoftware != null &&
                              !marketplaceStore.sourceIsUploading && (
                                <div>
                                  <FileZipOutlined />
                                  <p style={{ color: styleStore.primaryColor }}>
                                    {fileName}
                                  </p>
                                </div>
                              )}
                            <Upload
                              onChange={(file) => onPreview(file)}
                              customRequest={() => {}}
                              accept=".zip, .dmg"
                              showPreviewIcon={true}
                              showUploadList={
                                marketplaceStore.sourceIsUploading
                              }
                              maxCount={1}
                              disabled={software || errorMessage}
                            >
                              <Button
                                icon={<UploadOutlined />}
                                style={{
                                  opacity: software || errorMessage ? 0.5 : 1,
                                  pointerEvents:
                                    software || errorMessage ? "none" : "auto",
                                }}
                              >
                                Click to Upload
                              </Button>
                            </Upload>

                            <div className="accept-types">
                              Acceptable file formats: .zip, .dmg
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                    {type === "MOOC" && (
                      <>
                        <Row gutter={[0, 8]}>
                          <Col style={{ marginTop: "16px" }}>
                            Please provide a link to the MOOC:
                          </Col>
                        </Row>
                        <Row gutter={[0, 8]}>
                          <Col span={24} style={{ marginBottom: "16px" }}>
                            <Input
                              type="url"
                              placeholder="Enter a valid url"
                              onChange={(e) =>
                                handleMoocLinkChange(e.target.value)
                              }
                              disabled={previewSoftware}
                            />
                            {errorMessage && (
                              <div style={{ color: "red" }}>{errorMessage}</div>
                            )}
                          </Col>
                        </Row>
                      </>
                    )}
                  </Col>
                  <Col span={24} md={24} sm={24} lg={24} xxl={24}>
                    {preview && display_image && type === "image" && (
                      <Image
                        preview={false}
                        width={"400px"}
                        height={"400px"}
                        style={{ textAlign: "center" }}
                        src={previewImage}
                      />
                    )}
                    {console.log(preview3D)}
                    {preview3D && type === "3D" && (
                      <ModelViewer modelUrl={preview3D} />
                    )}
                    {/* {preview && type === "video" && (
                      <div
                        style={{
                          width: "50%",
                          textAlign: "center",
                          alignItems: "center",
                          alignContent: "center",
                          paddingLeft: "25%",
                        }}
                      >
                        <video width="100%" controls>
                          <source src={previewVideo} id="video" />
                          Your browser does not support HTML5 video.
                        </video>
                      </div>
                    )} */}
                    {/* {preview && type === "audio" && (
                      <audio id="myAudio" controls>
                        <source src={previewAudio} type="audio/mp3" />
                        Your browser does not support the audio element.
                      </audio>
                    )} */}
                  </Col>
                </Row>
              )}
              {/* step 2 */}
              {current === 1 && (
                <>
                  {NFT && (
                    <Form.Item
                      label="Choose Network"
                      name="chainId"
                      rules={[
                        {
                          required: false,
                          message: "Please input your Network!",
                        },
                      ]}
                    >
                      <Select
                        style={{ textAlign: "left" }}
                        placeholder={"network"}
                        onChange={(chainId) => OnNetworkChange(chainId)}
                        disabled={
                          chooseNetworkIsDisabled ||
                          !marketplaceStore.metaMaskIsConnected
                        }
                        loading={chooseNetworkIsDisabled}
                      >
                        {networks.map((item, index) => {
                          return (
                            <Option key={index} value={item.value}>
                              <Space>
                                <Avatar src={item.avatar_url}></Avatar>
                                {item.label}
                                <Popover content={item.description}>
                                  <InfoCircleOutlined
                                    style={{ fontSize: "20px" }}
                                  />
                                </Popover>
                              </Space>
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  )}

                  <p>Will you publish your work as NFT or Free item?</p>
                  <Space
                    direction="horizontal"
                    split={true}
                    wrap={true}
                    size={"middle"}
                  >
                    <Popover
                      content={
                        !marketplaceStore.metaMaskIsConnected
                          ? "Please connect to metaMask first!"
                          : "Create NFT item"
                      }
                    >
                      <Checkbox
                        disabled={!marketplaceStore.metaMaskIsConnected}
                        checked={NFT}
                        onChange={() => {
                          setNFT(true);
                          if (type === "MOOC") {
                            setUnlockableContent(true);
                          }
                        }}
                      >
                        NFT
                      </Checkbox>
                    </Popover>
                    <Popover content={"Create Free item"}>
                      <Checkbox checked={!NFT} onChange={() => setNFT(false)}>
                        Free
                      </Checkbox>
                    </Popover>
                  </Space>
                </>
              )}

              {/* step 4 */}

              {(current === 3 && NFT === true) ||
              (current === 2 && NFT === false) ? (
                <>
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your name!",
                      },
                    ]}
                  >
                    <Input placeholder="Name" />
                  </Form.Item>

                  <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                      {
                        required: false,
                        message: "Please input your description!",
                      },
                    ]}
                  >
                    <Input placeholder="Description" />
                  </Form.Item>

                  <Form.Item
                    label="Tags"
                    name="tags"
                    rules={[
                      {
                        required: false,
                        message: "Please input your tag!",
                      },
                    ]}
                  >
                    <Select placeholder={"Tags"} mode="tags">
                      {tags.map((item, index) => {
                        return (
                          <Option key={index} value={item.name}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="External link"
                    name="external_link"
                    rules={[
                      {
                        required: false,
                        message: "Please input your external_link!",
                        type: "url",
                      },
                    ]}
                  >
                    <Input placeholder="External link" />
                  </Form.Item>
                  {NFT && (
                    <Checkbox
                      onChange={onChangeUnlockableContent}
                      defaultChecked={type === "MOOC"}
                      disabled={type === "MOOC"}
                    >
                      Unlockable Content
                    </Checkbox>
                  )}
                  {unlockable_content && (
                    <Form.Item
                      label="Unlockable Content"
                      name="unlockable_content"
                      rules={[
                        {
                          required: type === "MOOC",
                          message: "Please input your unlockable content!",
                          type: "string",
                        },
                      ]}
                    >
                      <Input placeholder="Unlockable Content" />
                    </Form.Item>
                  )}
                  <Divider />
                  <Form.Item
                    wrapperCol={{
                      offset: 8,
                      span: 24,
                    }}
                  >
                    <Button type="primary" htmlType="submit">
                      Create Item
                    </Button>
                  </Form.Item>
                </>
              ) : (
                ""
              )}
            </Form>

            {current === 2 && NFT && (
              <Form
                name="create_nft"
                // initialValues={{
                //   remember: true,
                // }}
                onFinish={onCreateNFT}
                onFinishFailed={onFinishFailed}
                fields={NFTFormFields}
                autoComplete="off"
                layout="vertical"
                size="large"
                form={form_nft}
              >
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input name!",
                    },
                  ]}
                >
                  <Input placeholder="Name" />
                </Form.Item>

                <Form.Item
                  label={priceLabel}
                  name="price"
                  rules={[
                    {
                      required: true,
                      message: "Please input price!",
                      type: "number",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} placeholder="Price" />
                </Form.Item>

                <Form.Item
                  label="License"
                  name="license"
                  rules={[
                    {
                      required: true,
                      message: "Please input license!",
                    },
                  ]}
                >
                  <Select style={{ textAlign: "left" }} placeholder={"License"}>
                    {marketplaceStore.license.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Supply"
                  name="supply"
                  rules={[
                    {
                      required: true,
                      message: (
                        <p stytle={{ color: "green" }}>Please input supply!</p>
                      ),
                      type: "integer",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} placeholder="Supply" />
                </Form.Item>
                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 24,
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={onButtonClick}
                  >
                    Create NFT
                  </Button>
                </Form.Item>
              </Form>
            )}
          </>
        ) : (
          <Loader />
        )}
      </Modal>
    </div>
  );
};

export default observer(CreateItem);
