import React, { useRef, useEffect, useState } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

const ModelViewer = ({ modelUrl, textureUrls = [] }) => {
  const mountRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    if (!mountRef.current) return;

    // Scene setup
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      mountRef.current.clientWidth / mountRef.current.clientHeight,
      0.1,
      1000
    );

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(
      mountRef.current.clientWidth,
      mountRef.current.clientHeight
    );
    mountRef.current.appendChild(renderer.domElement);

    // Orbit controls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.25;
    controls.screenSpacePanning = false;
    controls.maxPolarAngle = Math.PI / 2;

    // Lighting
    const ambientLight = new THREE.AmbientLight(0x404040);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(5, 5, 5).normalize();
    scene.add(directionalLight);

    // Default texture for fallback
    const defaultTexture = new THREE.TextureLoader().load(
      "path/to/default/texture.png" // Provide the path to your default texture
    );

    // Function to convert base64 to Blob
    const base64ToBlob = (base64, mimeType) => {
      const byteString = atob(base64.split(",")[1]);
      const mime = mimeType || base64.split(",")[0].split(":")[1].split(";")[0];
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const intArray = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
      }
      return new Blob([arrayBuffer], { type: mime });
    };

    // Function to load textures
    const loadTextures = async (textureUrls) => {
      const textureLoader = new THREE.TextureLoader();
      const textures = await Promise.all(
        textureUrls.map(
          (url) =>
            new Promise((resolve) => {
              textureLoader.load(
                url,
                (texture) => resolve(texture),
                undefined,
                () => resolve(null) // Resolve with null on error
              );
            })
        )
      );
      return textures;
    };

    // Function to load and position the model dynamically
    const loadModel = async (url, textureUrls) => {
      const base64String = url.split(",")[1];
      const mimeType = url.split(",")[0].split(":")[1].split(";")[0];
      const fileExtension = mimeType.split("/")[1].toLowerCase();

      const fitModelToScene = (model) => {
        const box = new THREE.Box3().setFromObject(model);
        const center = box.getCenter(new THREE.Vector3());
        const size = box.getSize(new THREE.Vector3());

        model.position.x -= center.x;
        model.position.y -= center.y;
        model.position.z -= center.z;

        const maxDim = Math.max(size.x, size.y, size.z);
        const fov = camera.fov * (Math.PI / 180);
        let cameraZ = Math.abs((maxDim / 2) * Math.tan(fov / 2) * 2);

        camera.position.set(0, 0, cameraZ + 1);
        controls.target.copy(new THREE.Vector3(0, 0, 0));

        controls.update();
      };

      const textures = await loadTextures(textureUrls);

      if (fileExtension === "gltf-binary" || fileExtension === "glb") {
        const gltfLoader = new GLTFLoader();
        const blob = base64ToBlob(url, "model/gltf-binary");
        const objectURL = URL.createObjectURL(blob);

        gltfLoader.load(
          objectURL,
          (gltf) => {
            const hasEmbeddedMaterials = gltf.scene.traverse((child) => {
              if (child.isMesh && child.material && child.material.map) {
                return true;
              }
            });

            if (!hasEmbeddedMaterials) {
              applyTexturesToModel(gltf.scene, textures);
            }

            fitModelToScene(gltf.scene);
            scene.add(gltf.scene);
            animate();
          },
          undefined,
          (error) => {
            console.error(
              "An error occurred while loading the GLTF model:",
              error
            );
          }
        );
      } else if (fileExtension === "octet-stream" || fileExtension === "fbx") {
        const fbxLoader = new FBXLoader();
        const blob = base64ToBlob(url, "application/octet-stream");
        const objectURL = URL.createObjectURL(blob);

        fbxLoader.load(
          objectURL,
          (fbx) => {
            // Check for embedded materials in the FBX model
            const hasEmbeddedMaterials = fbx.traverse((child) => {
              if (child.isMesh && child.material && child.material.map) {
                return true;
              }
            });

            if (!hasEmbeddedMaterials) {
              applyTexturesToModel(fbx, textures);
            }

            fitModelToScene(fbx);
            scene.add(fbx);
            animate();
          },
          undefined,
          (error) => {
            console.error(
              "An error occurred while loading the FBX model:",
              error
            );
          }
        );
      } else {
        console.error("Unsupported model format:", fileExtension);
      }
    };

    // Function to apply textures or fallback materials to the model
    const applyTexturesToModel = (model, textures) => {
      let hasMaterials = false;

      // Map texture URLs by filename for easy lookup
      const textureMap = {};
      textures.forEach((texture) => {
        const textureName = texture.image.src.split("/").pop().split(".")[0];
        textureMap[textureName] = texture;
      });

      model.traverse((child) => {
        if (child.isMesh) {
          const materialName = child.material?.name || "Unnamed_Material";
          console.log(`Material Name: ${materialName}`);
          hasMaterials = true;

          // Check if there's a matching texture by name
          const matchingTexture = textureMap[materialName];

          if (matchingTexture) {
            console.log(`Applying matching texture for: ${materialName}`);
            child.material.map = matchingTexture;
          } else if (!child.material.map) {
            // Apply default texture if no specific match found
            console.warn(
              `No matching texture for ${materialName}. Applying default.`
            );
            child.material.map = defaultTexture;
          }

          // Ensure the material updates in the renderer
          child.material.needsUpdate = true;
        }
      });

      // Change background color based on material presence
      if (!hasMaterials) {
        scene.background = new THREE.Color(0x333333); // Set fallback color if no materials
      } else {
        scene.background = null; // Revert to natural color when materials are present
      }
    };

    loadModel(modelUrl, textureUrls);

    let isAnimating = true;

    const animate = () => {
      if (!isAnimating) return;
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };

    animate();

    const handleDoubleClick = () => {
      setIsFullscreen(true);
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape" && isFullscreen) {
        setIsFullscreen(false);
      }
    };

    mountRef.current.addEventListener("dblclick", handleDoubleClick);
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup function
    return () => {
      isAnimating = false;
      controls.dispose();
      if (mountRef.current) {
        mountRef.current.removeEventListener("dblclick", handleDoubleClick);
        mountRef.current.removeChild(renderer.domElement);
      }
      document.removeEventListener("keydown", handleKeyDown);
      renderer.dispose();
    };
  }, [modelUrl, textureUrls, isFullscreen]);

  return (
    <div
      style={{
        position: isFullscreen ? "fixed" : "relative",
        top: isFullscreen ? 0 : "auto",
        left: isFullscreen ? 0 : "auto",
        width: isFullscreen ? "100vw" : "100%",
        height: isFullscreen ? "100vh" : "400px",
        backgroundColor: isFullscreen
          ? "rgba(255, 255, 255, 0.75)"
          : "transparent",
        zIndex: isFullscreen ? 1000 : 1,
        opacity: 0.75,
        borderRadius: isFullscreen ? 0 : 15,
        marginBottom: isFullscreen ? 0 : 10,
      }}
    >
      <div
        ref={mountRef}
        style={{
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );
};

export default ModelViewer;
